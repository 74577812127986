import * as React from 'react'
import Button from '@eversports/klimt-primitives/Button'
import Text from '@eversports/klimt-primitives/Text'
import SeoLink from '@eversports/klimt-primitives/SeoLink'

import { Localized } from '../../../../localization/react'
import getLinkToVenueFirstOffering from '../../../../helpers/get-link-to-venue-first-offering'
import { VenueOfferingType } from '../../../../App.types'

interface Props {
  offerings: Array<VenueOfferingType>
  slug: string
}

const CallToActionButton = ({ offerings, slug }: Props) => {
  const firstVenueOffering = offerings[0]
  const linkToVenueFirstOffering = getLinkToVenueFirstOffering({ firstVenueOffering, venueSlug: slug })

  return (
    <SeoLink encodedTo={btoa(linkToVenueFirstOffering)} external type="hidden">
      <Button
        variant="primary"
        sx={{ p: 3, width: '100%' }}
        disabled={!firstVenueOffering}
        data-testid="book-now-action"
      >
        <Text sx={{ textTransform: 'none', color: 'white', fontWeight: 'bold' }}>
          <Localized id="venue-profile-book-now" />
        </Text>
      </Button>
    </SeoLink>
  )
}

export default CallToActionButton
